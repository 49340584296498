import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams, useNavigate } from "react-router-dom";
import { get, post } from "../api/api";
import SurveyElement, { Survey } from "../components/surveyElement/SurveyElement";

export interface User {
    Id: string,
    Name: string,
    IsChosen: boolean
}

const SurveyPage = () => {
    const [survey, setSurvey] = useState<Survey | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isUnknownLink, setIsUnknownLink] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>(null);

    let { surveyId, userId } = useParams();

    const [createUser, setCreateUser] = useState<boolean>(false);
    const [creatingUser, setCreatingUser] = useState<boolean>(false);

    const [cookies, setCookie] = useCookies(['surveyUser']);

    const navigate = useNavigate();

    const showDialog = () => {
        setCreateUser(true);
        document.body.classList.add("noScroll");
    }

    const hideDialog = () => {
        setCreateUser(false);
        document.body.classList.remove("noScroll");
    }

    const updateCookie = (sId: string, uId: string) => {
        const cookie = cookies.surveyUser ?? {};
        cookie[sId] = uId;
        setCookie("surveyUser", cookie, {
            maxAge: 30 * 24 * 3600
        });
    }

    const setupWithUser = () => {
        get(`/survey/${surveyId}/${userId}`).then((data) => {
            if (data === "unknown") {
                setIsUnknownLink(true);
            } else {
                updateCookie(surveyId!, userId!);
                setSurvey(data.Survey);
                setUser(data.User);
            }
            setIsLoading(false);
        });
    }

    const setupWithoutUser = () => {
        get(`/survey/${surveyId}`).then((data) => {
            if (data === "unknown") {
                setIsUnknownLink(true);
            } else {
                setSurvey(data);
                showDialog();
            }
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (userId) {
            setupWithUser();
        } else {
            const cookie = cookies.surveyUser ?? {};
            if (surveyId && cookie[surveyId]) {
                navigate(cookie[surveyId]);
                userId = cookie[surveyId];
                setupWithUser();
            } else {
                setupWithoutUser();
            }
        }
    }, []);
    if (isLoading || !isUnknownLink && !survey) {
        return <div>Loading...</div>
    } else if (isUnknownLink) {
        return <div>Ungültiger Link</div>
    } else {
        return <>
            {createUser && <CreateUserDialog onConfirm={(name) => {
                if (name.length === 0) return;
                setCreatingUser(true);

                post(`/user/${surveyId}/create/${name}`).then(user => {
                    navigate(user.Id);
                    userId = user.Id;
                    setCookie("surveyUser", {SurveyId: surveyId, UserId: userId});
                    updateCookie(surveyId!, userId!);
                    get(`/survey/${surveyId}/${userId}`).then((data) => {
                        setSurvey(data.Survey);
                        setUser(data.User);
                        hideDialog();
                        setCreatingUser(false);
                    });
                });
            }} Loading={creatingUser} />}
            <SurveyElement Survey={survey as Survey} User={user} Refresh={(callback) => {
                get(`/survey/${surveyId}/${userId}`).then((data) => {
                    setSurvey(data.Survey);
                    callback();
                });
            }} />
        </>
    }
}

interface CreateUserDialogProps {
    onConfirm: (name: string) => void
    Loading: boolean
}
const CreateUserDialog = (props: CreateUserDialogProps) => {
    const [name, setName] = useState<string>("");
    const { onConfirm, Loading } = props;

    return <div className="ui-window ui-overlay fx-insert-setup">
        <div className="ui-window-wrap fx-wrap">
            <div className="window-header">
                <div className="title-large">Namen eingeben</div>
                <div className="ui-space"></div>
            </div>
            <div className="window-tabs ui-tab dark">
                <div className="ui-tab-wrap">
                    <div className="ui-tab-item">
                        <span>Bitte gib deinen Namen ein</span>
                    </div>
                </div>
            </div>
            <div className="window-wrap">
                <div className="window-cont">
                    <div className="fx-user-select">
                        <div className="col">
                            <div className="label">Name</div>
                            <div>
                                <input className="createUserInput" onChange={(e) => setName(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="window-footer">
                    <button className="btn-primary" onClick={() => onConfirm(name)} disabled={Loading}>
                        <svg className="svg-inline--fa fa-check" aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path className="" fill="currentColor" d="M440.1 103C450.3 112.4 450.3 127.6 440.1 136.1L176.1 400.1C167.6 410.3 152.4 410.3 143 400.1L7.029 264.1C-2.343 255.6-2.343 240.4 7.029 231C16.4 221.7 31.6 221.7 40.97 231L160 350.1L407 103C416.4 93.66 431.6 93.66 440.1 103V103z"></path></svg>
                        Bestätigen
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default SurveyPage;