import { useState } from "react"
import "./SurveyElement.css"
import { post } from '../../api/api'
import { User } from "../../pages/SurveyPage"

interface Position {
    Id: string
    Title: string
    Qualification: string
    Votes: User[]
}

interface Task {
    Id: string
    Title: string
    Date: string
    Start: string
    End: string
    Information: string
    IsActive: boolean
    Positions: Position[]
    EndDate: string
    EndTime: string
    CanBeVotedOn: boolean
}

interface Props {
    Survey: Survey,
    User: User | null,
    Refresh: (callback: () => void) => void
}

export interface Survey {
    Id: string
    Tasks: Task[]
}

interface DialogData {
    Title: string
    Subtitle: string
    InfoText: string
    Name: string
    What: string
    onConfirm: () => void
    Loading: boolean
    ButtonClass: string
}

interface DialogProps extends DialogData {
    onClose: () => void
}
const ConfirmDialog = (props: DialogProps) => {
    const { Title, Subtitle, Name, What, onConfirm, onClose, Loading, ButtonClass, InfoText } = props;

    return <div className="ui-window ui-overlay fx-insert-setup">
        <div className="ui-window-wrap fx-wrap">
            <div className="window-opt">
                <div className="opt" onClick={onClose}>
                    <svg className="svg-inline--fa fa-xmark" aria-hidden="true" focusable="false" data-prefix="far" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path className="" fill="currentColor" d="M312.1 375c9.369 9.369 9.369 24.57 0 33.94s-24.57 9.369-33.94 0L160 289.9l-119 119c-9.369 9.369-24.57 9.369-33.94 0s-9.369-24.57 0-33.94L126.1 256L7.027 136.1c-9.369-9.369-9.369-24.57 0-33.94s24.57-9.369 33.94 0L160 222.1l119-119c9.369-9.369 24.57-9.369 33.94 0s9.369 24.57 0 33.94L193.9 256L312.1 375z"></path></svg>
                </div>
            </div>
            <div className="window-header">
                <div className="title-large">{Title}</div>
                <div className="ui-space"></div>
            </div>
            <div className="infoText" dangerouslySetInnerHTML={{__html:InfoText}}></div>
            <div className="window-tabs ui-tab dark">
                <div className="ui-tab-wrap">
                    <div className="ui-tab-item">
                        <span style={{ cursor: "default" }}>{Subtitle}</span>
                    </div>
                </div>
            </div>
            <div className="window-wrap">
                <div className="window-cont">
                    <div className="fx-user-select">
                        <div className="wrap-input">
                            <div className="col">
                                <div className="label">Name</div>
                                <div className="input self">
                                    <div className="name">{Name}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="input">
                        <div className="label-row sub">
                            <div className="col color-info">Dienst</div>
                        </div>
                        <div className="fx-box-input">{What}</div>
                    </div>
                </div>
                <div className="window-footer">
                    <button className={ButtonClass} onClick={onConfirm} disabled={Loading}>
                        <svg className="svg-inline--fa fa-check" aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path className="" fill="currentColor" d="M440.1 103C450.3 112.4 450.3 127.6 440.1 136.1L176.1 400.1C167.6 410.3 152.4 410.3 143 400.1L7.029 264.1C-2.343 255.6-2.343 240.4 7.029 231C16.4 221.7 31.6 221.7 40.97 231L160 350.1L407 103C416.4 93.66 431.6 93.66 440.1 103V103z"></path></svg>
                        {Title}
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default (props: Props) => {

    const { User, Survey, Refresh } = props;

    const { Tasks } = Survey;

    const [dialogData, setDialogData] = useState<DialogData | null>(null)

    const [dialogIsLoading, setDialogIsLoading] = useState<boolean>(false)

    const didAccept = (position: Position) => {
        if (User === null) return false;
        return position.Votes.filter(x => x.Id === User.Id).length > 0;
    }

    const showDialog = (dialogData: DialogData) => {
        setDialogData(dialogData);
        document.body.classList.add("noScroll");
    }

    const hideDialog = () => {
        setDialogData(null);
        document.body.classList.remove("noScroll");
    }

    let canBeVotedOn = false;
    return <>
        {dialogData && <ConfirmDialog
            Name={dialogData.Name}
            Subtitle={dialogData.Subtitle}
            Title={dialogData.Title}
            What={dialogData.What}
            onClose={hideDialog}
            onConfirm={dialogData.onConfirm}
            Loading={dialogIsLoading}
            ButtonClass={dialogData.ButtonClass}
            InfoText={dialogData.InfoText}
        />}
        {Tasks.map(task => {
            const isDoneButCanStillVote = !task.IsActive && task.Positions.some(x => x.Votes.length === 0 || !x.Votes.some(y => y.IsChosen));
            const didAlreadyVoteOnClosedVote = User?.Id ? !task.IsActive && task.Positions.some(x => x.Votes.map(y => y.Id).includes(User?.Id)) : false;

            const renderHeader = !canBeVotedOn && task.CanBeVotedOn || canBeVotedOn && !task.CanBeVotedOn;
            const headerTitle = task.CanBeVotedOn ? "Hier kannst du noch abstimmen" : "Hier kannst du nicht mehr abstimmen";
            canBeVotedOn = task.CanBeVotedOn;
            return <div key={task.Id}>
                {renderHeader && <div className="divider"><span>{headerTitle}</span></div>}
                <div className="container">
                    {!task.IsActive ? <div className="next-border">
                        <div className="ui-alert info hover append">
                            <div className="row-base">
                                <div className="ico">
                                    <svg className="svg-inline--fa fa-clock" aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="" fill="currentColor" d="M232 120C232 106.7 242.7 96 256 96C269.3 96 280 106.7 280 120V243.2L365.3 300C376.3 307.4 379.3 322.3 371.1 333.3C364.6 344.3 349.7 347.3 338.7 339.1L242.7 275.1C236 271.5 232 264 232 255.1L232 120zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256z"></path></svg>
                                </div>
                                <div className="text">
                                    {!task.IsActive && task.CanBeVotedOn ? "Hier kannst du dich direkt eintragen" : "Die Auslosung ist abgeschlossen"}
                                </div>
                            </div>
                        </div>
                    </div> : null}
                    <div className="ui-card default fx-card-list">
                        <div className="ui-card-header fx-list-header">
                            <div className="text">
                                <div className="title unselect">
                                    <span>{task.Title}</span>
                                </div>
                                <div className="ui-space"></div>
                            </div>
                        </div>
                        <div className="ui-card-specs space-15">
                            <div className="row">
                                <div className="col" style={{width: "140px"}}>
                                    <b>Veranstaltungsdatum</b>
                                </div>
                                <div className="col">
                                    <b>{`${task.Date} ${task.Start} - ${task.End} Uhr`}</b>
                                </div>
                                <div className="flex"></div>
                            </div>
                            {task.IsActive && <div className="row">
                                <div className="col" style={{width: "140px"}}>
                                    <span style={{marginLeft: 0}}>Auslosung am</span>
                                </div>
                                <div className="col">
                                    <span style={{marginLeft: 0}}>{`${task.EndDate} ${task.EndTime}`}</span>
                                </div>
                                <div className="flex"></div>
                            </div>}
                        </div>
                        <div className="fx-content editor">
                            <div className="ui-editor-readonly">
                                <div>
                                    <div className="ProseMirror">
                                        <p>{task.Information}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ui-card-cont">
                            <div className="fx-table-scroll">
                                <div className="fx-table-scroll-cont">
                                    <table className="ui-table-block gray fx-break">
                                        <thead>
                                            <tr className="header-info">
                                                <th></th>
                                                <th>Posten</th>
                                                <th>Qualifikation</th>
                                                {(task.IsActive || isDoneButCanStillVote && !didAlreadyVoteOnClosedVote) && <th>{isDoneButCanStillVote ? "Eintragen" : "Interesse"}</th>}
                                            </tr>
                                        </thead>
                                        {task.Positions.map(position => {
                                            var accepted = didAccept(position)
                                            return <tbody key={`${task.Id}${position.Id}`}>
                                                <tr className="hover">
                                                    <td className="ico"></td>
                                                    <td className="fx-bold">
                                                        <div className="fx-break-cont" style={{ paddingBottom: (position.Votes.length > 0 ? "5px" : "10px") }}>
                                                            {position.Title}
                                                        </div>
                                                        {position.Votes.map(vote => <div key={`${task.Id}${position.Id}{${vote.Id}}`} className={`vote ${vote.Id === User?.Id ? "bold" : ""} ${!task.IsActive ? vote.IsChosen ? "winner" : "loser" : ""}`}>{vote.Name}</div>)}
                                                    </td>
                                                    <td>
                                                        <div className="fx-break-cont">
                                                            {position.Qualification}
                                                        </div>
                                                    </td>
                                                    {(task.IsActive || isDoneButCanStillVote && (position.Votes.length === 0 || !position.Votes.some(y => y.IsChosen)) && !didAlreadyVoteOnClosedVote) && <td className={`td-opt ${accepted ? "color-negative" : "color-primary"}`} onClick={() => {
                                                        if (User !== null) {
                                                            showDialog({
                                                                Name: (User as User).Name,
                                                                Subtitle: `${task.Title} - ${task.Date}`,
                                                                Title: accepted ? "Austragen" : "Eintragen",
                                                                ButtonClass: accepted ? "btn-negative" : "btn-primary",
                                                                What: position.Title,
                                                                onConfirm: () => {
                                                                    setDialogIsLoading(true)
                                                                    post(`/survey/${(accepted ? "un" : "")}acceptPosition/${position.Id}/${(User as User).Id}`).then(res => {
                                                                        if (res.status === "OK") {
                                                                            Refresh(() => {
                                                                                hideDialog()
                                                                                setDialogIsLoading(false)
                                                                            })
                                                                        }
                                                                    })
                                                                },
                                                                Loading: dialogIsLoading,
                                                                InfoText: isDoneButCanStillVote ? "Wenn du dich jetzt einträgst, bist du für diese BSW eingeteilt. Du kannst dich nicht wieder austragen." : !accepted ? `Falls es mehrere Interessenten gibt, findet am ${task.EndDate} um ${task.EndTime} Uhr eine automatische Auslosung statt. Danach kannst du dich über deinen Link (<a href="${window.location.href}">${window.location.href}</a>) wieder anmelden und du siehst, ob du bei der BSW dabei bist oder nicht.
                                                        Wenn der Auslosungs-Zeitraum schon vorbei ist oder du dich alleine eingetragen hast, bist du automatisch zur BSW eingeteilt.` : ""
                                                            })
                                                        }
                                                    }}>
                                                        <div className="fx-break-cont">
                                                            {accepted ? "Ich habe keine Zeit mehr" : isDoneButCanStillVote ? "Eintragen" : "Ich habe Zeit"}
                                                        </div>
                                                    </td>}
                                                    {!task.IsActive && isDoneButCanStillVote && position.Votes.length > 0 && position.Votes.some(y => y.IsChosen) && !didAlreadyVoteOnClosedVote && <td></td>}
                                                    <td className="ico"></td>
                                                </tr>
                                            </tbody>
                                        })}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })}
    </>
}