import "./App.css";
import { Route, Routes } from "react-router-dom";
import SurveyPage from "./pages/SurveyPage";
import { useEffect } from "react";

const App = () => {

  useEffect(() => { document.title = "Feuerwehr Diepholz Umfragen" }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/:surveyId" element={<SurveyPage />} />
        <Route path="/:surveyId/:userId" element={<SurveyPage />} />
        <Route path="/" element={<div>Hier ist nichts</div>} />
      </Routes>
    </div>
  );
}

export default App;